<script>
/**
 * Footer Component
 */
export default {}
</script>

<template lang="pug">
// Footer Start
footer.footer
  .container-fluid
    .row
      .col-md-6
        | {{ new Date().getFullYear() }} &copy; 開發團隊 <a href="https://jbravo.com.tw">嵩鴻數位有限公司</a>
// end Footer
</template>
