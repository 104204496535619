<script>
/**
 * Topbar component
 */
export default {
  data () {
    return {
      languages: [
        {
          flag: require("@/assets/images/flags/us.jpg"),
          language: "en",
          title: "English",
        },
        {
          flag: require("@/assets/images/flags/french.jpg"),
          language: "fr",
          title: "French",
        },
        {
          flag: require("@/assets/images/flags/spain.jpg"),
          language: "es",
          title: "Spanish",
        },
        {
          flag: require("@/assets/images/flags/china.png"),
          language: "zh",
          title: "Chinese",
        },
        {
          flag: require("@/assets/images/flags/arabic.png"),
          language: "ar",
          title: "Arabic",
        },
      ],
      current_language: this.$i18n.locale,
      text: null,
      flag: null,
      value: null,
    };
  },
  mounted () {
    this.value = this.languages.find((x) => x.language === this.$i18n.locale);
    this.text = this.value.title;
    this.flag = this.value.flag;
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu () {
      this.$parent.toggleMenu();
    },
    /**
     * Full screen
     */
    initFullScreen () {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    /**
     * Toggle rightbar
     */
    toggleRightSidebar () {
      this.$parent.toggleRightSidebar();
    },
    /**
     * Set languages
     */
    setLanguage (locale, country, flag) {
      this.$i18n.locale = locale;
      this.current_language = locale;
      this.text = country;
      this.flag = flag;
    },
    /**
     * Horizontal-toggle menu
     */
    horizonalmenu () {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    // 請求進行登出操作
    setMemberLogout () {
      let vm = this
      this.$root.apis.setMemberLogout(function (_response) {
        vm.$root.login_status = false
        vm.$root.user = { id: false }
        vm.$root.common.showSingleNotify('已經登出成功')
        console.log('=> 引導至登入頁')
        return vm.$router.push({ name: 'login', params: {} })
      })
    }
  },
};
</script>

<template lang="pug">
.navbar-custom
  .container-fluid
    ul.list-unstyled.topnav-menu.float-right.mb-0
      b-nav-item-dropdown.notification-list.topbar-dropdown(
        right="",
        menu-class="profile-dropdown",
        toggle-class="p-0"
      )
        template.nav-link.dropdown-toggle(slot="button-content")
          .nav-user.mr-0
            i.fe-settings.noti-icon.toggle-right
        b-dropdown-header
          h5.text-overflow.m-0.py-2 {{ $root.user.name }}
          //- h6 {{ $root.user.department }} / {{ $root.user.position }}
        a.dropdown-item(@click="setMemberLogout")
          i.fe-log-out.mr-1
          span {{ $t('navbar.dropdown.name.list.logout') }}
    ul.list-unstyled.topnav-menu.mb-0
      li
        button.button-menu-mobile.waves-effect.waves-light(@click="toggleMenu")
          i.fe-menu

    .clearfix
</template>

<style lang="sass" scoped>
.navbar-custom
  background: #0073b8
  box-shadow: none
</style>