export const menuItems = [
    {
        id: 1,
        icon: "fe-file",
        permissions: "MENU_FILE_SETTING",
        label: '檔案列表',
        link: '/'
    }
];

